export const bannerPhotos = {
  spring: [
    "https://www.dropbox.com/scl/fi/3a4qjov8i85qra7xzm62w/01.jpg?rlkey=bsu7dl3hqw7ukbedx3wrybeab&st=b9ggwhdt&raw=1",
    "https://www.dropbox.com/scl/fi/ef87kq6tmy3dv81zjkdso/02.jpg?rlkey=lbjri5fgekewgr5lb5lzwhsmw&st=olcsjvi8&raw=1",
    "https://www.dropbox.com/scl/fi/f7br5payn86n82atryl94/03.jpg?rlkey=62g5s1i5v4dctpltzbu6yefcr&st=996x894l&raw=1",
    "https://www.dropbox.com/scl/fi/frn5bvswsblpvadfrxgps/04.jpg?rlkey=prthunhdy1bkooxgez2ytranb&st=1ug4p2ki&raw=1",
    "https://www.dropbox.com/scl/fi/7oppl9hslnkuwh5vgzxwn/05.jpg?rlkey=9ax2ca82ejmnvbqclzdcmf8di&st=lli9oh3a&raw=1",
    "https://www.dropbox.com/scl/fi/vhr6pqcs43qb7y5nsf6vw/06.jpg?rlkey=udp2ut8y1y7jcnu0pw3mdlz1j&st=2j6jwhb4&raw=1",
    "https://www.dropbox.com/scl/fi/ov23w88myug8wog16gem5/07.jpg?rlkey=mz6e2x6hlz46kxhlpug5todqr&st=8nlkih3r&raw=1",
    "https://www.dropbox.com/scl/fi/1zf8wiztf83tyat5opzml/08.jpg?rlkey=m7jytfql13d9fjmi3bt3ake7d&st=m7e5ryj1&raw=1",
    "https://www.dropbox.com/scl/fi/rl1d7ozmwwoqhlepr3du8/09.jpg?rlkey=aykg6bdudpsb6h6qdqk7okeeh&st=p50ybn2a&raw=1",
    "https://www.dropbox.com/scl/fi/8jij5nekavdl2eoqrngrx/10.jpg?rlkey=heio6pbx6efoobjk78ce7hdba&st=mh4spcj3&raw=1",
    "https://www.dropbox.com/scl/fi/e7iv02yl9nprm6nfsuhz1/11.jpg?rlkey=3raknffhs1kct1ihf23idtaoi&st=ptqfwmt9&raw=1",
    "https://www.dropbox.com/scl/fi/wpg0ybctjxrm8bpq0zgem/12.jpg?rlkey=4r8tt2ch7412f62xfofgwrk5w&st=b5m3l6l4&raw=1",
  ],
  summer: [
    "https://www.dropbox.com/scl/fi/ducdde9bgn7tjjz2rsj9j/2BF8F146-4AE6-470D-A249-0C4547EF1DF2.jpg?rlkey=wzzrppq6mfmp9ii13btczarqb&st=qithgezi&raw=1",
    "https://www.dropbox.com/scl/fi/nsa3q7hm4craccgjicxcx/9D3E8D64-7B25-409A-AED6-882B58737436.jpg?rlkey=rioin9iilugmix7g35yq3j8ly&st=nx419qr1&raw=1",
    "https://www.dropbox.com/scl/fi/vrm2pphncwzokx6ykt3vn/40F12453-4A9C-4049-8EE7-BE4FD384A02B.jpg?rlkey=45k4yjmfi6gerjypxx3xms99p&st=z6c6alo9&raw=1",
    "https://www.dropbox.com/scl/fi/qioq9dg0stdq43xvuhdez/image0.jpg?rlkey=bxxf0g80j3ky8y2tgvxj6kz76&st=w32n0abg&raw=1",
    "https://www.dropbox.com/scl/fi/0rywiwfpumlb4w2tox2m0/IMG_1298.jpg?rlkey=mcceqxpmdlvysa49esjok5f9z&st=1dpx37yu&raw=1",
    "https://www.dropbox.com/scl/fi/olpsy7ivf8ljv9ztincgv/IMG_1687.jpg?rlkey=vla4eac1q2f7sjpxqwnfm9amy&st=dkg81iuc&raw=1",
    "https://www.dropbox.com/scl/fi/7t7t3ca63okgd282ascb6/IMG_1695.jpg?rlkey=cg4oyp3bnubg06da5bnjtxf9y&st=vnoggr95&raw=1",
    "https://www.dropbox.com/scl/fi/xxmvznd7jrjty8xa7w3me/IMG_2532.jpg?rlkey=dnuapbp63v8znfrf5d7xpq2sk&st=iodizo5a&raw=1",
    "https://www.dropbox.com/scl/fi/m4e58vbkycih5ucu8qg38/IMG_8791.jpg?rlkey=r8mr64ptmw95dg3tr9w60547m&st=v5he4t81&raw=1",
  ],
  fall: [
    "https://www.dropbox.com/scl/fi/xgmgl98vfg5q31unn69wt/0H0A8607-sharpen-1.jpg?rlkey=e5oip9i7yor74znxer1tydrjl&raw=1",
    "https://www.dropbox.com/scl/fi/z53bynywlir54q3t9gdjq/763542D0-C83B-43E4-914D-219BD6E74B21.jpg?rlkey=k7gyvt9fjq2e5ttnafq8fm1fp&raw=1",
    "https://www.dropbox.com/scl/fi/52tlcqljv65gprpcmgb71/20221007_134928-01.jpg?rlkey=pjfnswzjxob36olagr3s07ixn&raw=1",
    "https://www.dropbox.com/scl/fi/d8ne3h86hpy1dl4vzs91q/Bandyanas-1.jpg?rlkey=pgbl3h03191kzm8inbspitgrv&raw=1",
    "https://www.dropbox.com/scl/fi/gawhf3a8x32sangs8eb8a/D5DD426E-E18F-486C-8060-683E5294F6FD.jpg?rlkey=2vyn24v40ob8ai8g0iae337wb&raw=1",
    "https://www.dropbox.com/scl/fi/bn2idepyocrn7dzk274z8/DVNI3119.jpg?rlkey=umlbp3uwv4o6pucvnz6az1dhb&raw=1",
    "https://www.dropbox.com/scl/fi/bans7b3xy292tfhl08wc6/IMG_0801.jpg?rlkey=rfk6dfwitnkyp94zppmvzjpta&raw=1",
    "https://www.dropbox.com/scl/fi/u0u57xqiwq4dlnf1m3jqp/IMG_1096.jpg?rlkey=r4ae434vyen26n9zb49j371bo&raw=1",
    "https://www.dropbox.com/scl/fi/wad9geea271zjyr4zhwav/IMG_1197.jpg?rlkey=yjlpnpezfdhjhd45veaoagr00&raw=1",
    "https://www.dropbox.com/scl/fi/dd4hnn02lipt999h0vxbn/IMG_1347.jpg?rlkey=no9ivt7sb6egqxivpkfe2dr67&raw=1",
    "https://www.dropbox.com/scl/fi/xby02qwk2zynglb4i8y4i/IMG_5271.jpg?rlkey=uewmvuhxb1cyrb80lzh4urix3&raw=1",
  ],
  winter: [
    "https://www.dropbox.com/scl/fi/7xi5s3p7vke9th1d33ill/01_santa_paws.jpg?rlkey=pw8zz48gnnt382k6x0kxyiz6m&st=l9r4xmhh&raw=1",
    "https://www.dropbox.com/scl/fi/6q3nl5ai3o7rffwrrfeuq/02_whisky.jpg?rlkey=2tl45d7mxxbu5ukkce2dnmdk2&st=b644zhci&raw=1",
    "https://www.dropbox.com/scl/fi/pw6oombtazhvcq3brhzqe/03_bowtie.jpg?rlkey=2sbkphf9krmzgzp6c0u7la2cp&st=jtkra4ok&raw=1",
    "https://www.dropbox.com/scl/fi/2jpxsewweu7h0nk89tw8b/04-crosby.jpg?rlkey=hrpi73qdz4stasn3xcakja54y&st=dx00mhai&raw=1",
    "https://www.dropbox.com/scl/fi/prba3q0cjwx2bajq61a1z/05_red_plaid.jpg?rlkey=8pvfv2ko566y8zja1c4s83wy5&st=uf6ifzya&raw=1",
    "https://www.dropbox.com/scl/fi/5xl3mjwt15lmf2bs5jn24/06_green_plaid.jpg?rlkey=48wjrbkw8bj4r00lzejsw4rtg&st=z38vf8xi&raw=1",
    "https://www.dropbox.com/scl/fi/oi0k67c2y1shsadizznte/07_kisses.jpg?rlkey=9yvco41s91zp4rco2vfvzik4t&st=14pv1p4o&raw=1",
    "https://www.dropbox.com/scl/fi/7ptkp0knkkv1gbfhxqixz/08_ellie.jpg?rlkey=avchwackc5f6huezi0wv86mha&st=4rf88v7z&raw=1",
  ],
};
