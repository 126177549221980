<template>
  <div class="d-flex flex-row fixed-top banner">
    <div v-for="(img, i) in shuffledSlides" :key="i" ref="bannerDiv">
      <img class="banner-image d-block" :id="i" :src="img" alt="banner image" />
    </div>
  </div>
</template>
<script>
import { bannerPhotos } from "@/assets/photo_links/BannerPhotos";

export default {
  data() {
    return {
      monthToSeason: {
        0: "winter",
        1: "winter",
        2: "spring",
        3: "spring",
        4: "spring",
        5: "summer",
        6: "summer",
        7: "summer",
        8: "fall",
        9: "fall",
        10: "fall",
        11: "winter",
      }
    };
  },
  computed: {
    shuffledSlides() {
      return this.shuffleArray(bannerPhotos[this.currentSeason]);
    },
    currentSeason() {
      const month = new Date().getMonth();
      return this.monthToSeason[month];
    },
  },
  methods: {
    shuffleArray(array) {
      const newArray = [...array];
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
      return newArray;
    },
  },
};
</script>

<style>
.banner-image {
  height: 600px;
}
.banner {
  z-index: -1;
}
@media only screen and (max-width: 1200px) {
  .banner-image {
    height: 500px;
  }
}
@media only screen and (max-width: 800px) {
  .banner-image {
    height: 400px;
  }
}
@media only screen and (max-width: 550px) {
  .banner-image {
    height: 200px;
  }
}
</style>
